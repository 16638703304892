import React, { useState, useRef, useLayoutEffect } from "react"
import { Link } from "gatsby"
import logo from "../images/eidosstudio.svg"
import Contents from "../pages/menu-content"
import {gsap} from 'gsap'
import { useLocation } from "@reach/router"


const Nav = () => {
const [isMenuOpen, setIsMenuOpen] = useState(false);

let menuReveal = useRef(null);


const menuFunction = (e) => {
  e.preventDefault() // Operate the drop down
  !isMenuOpen ? setIsMenuOpen(true) : setIsMenuOpen(false)
  gsap.from(menuReveal, { xPercent: 100, opacity: 0, ease: 'Power3.easeOut', duration: .6 });
  gsap.to(menuReveal, { xPercent: 0, opacity: 1, ease: 'Power3.easeOut', duration: .6});
}

const location = useLocation();
const menuColor = location.pathname !== '/'  ? 'black' : 'gray-50'
const menuBgColor = location.pathname !== '/'  ? 'bg-white' : 'bg-transparent'


const [scrollthisman, setScrollthisman] = useState(false);

useLayoutEffect(() => {
  var lastScrollTop = window.pageYOffset;
  const handleScroll = () => {
      var y = window.pageYOffset || document.documentElement.scrollTop;
      // SCROLL MENU
      if (y > lastScrollTop && y > 300 ) { setScrollthisman('slideOutUp')}
      else {setScrollthisman('slideInDown') }
      lastScrollTop = y <= 0 ? 0 : y;
  }
    window.addEventListener("scroll", handleScroll);   
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);



return (
  <>
  <div className={"w-100 animate-06  db fixed top-0 right-0 z-9999  bb b-black-05  " + scrollthisman }>
      <div className={'w-100 ' + menuBgColor}  >
      <div className="w-100 mw-1400 center flex items-center justify-between h5">
        <Link className="w-100 flex link pl1 pointer" to={`/`}>
           
                    <div className="flex items-center justify-between ">
                    <div className="flex items-center"><img className="h24" src={logo} alt="...." /></div>
                    <div className={'f4 fw6 gray-200 w-100 pl05 ' + menuColor}>eidos studio</div>
                    </div>
           
        </Link>

        <div onClick={menuFunction}  className="flex flex-end black-900 ph1">
          <div className="link f7 fw7 black-900 flex items-center ls05 ttu pointer">
            <div className={'pr05 ls-md ' + menuColor}>menu</div> 
            <svg className={'h24 mr05 ' + menuColor} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" /></svg>
          </div>
        </div>

      </div>

      <div id="contentMenu" ref={el => {menuReveal = el}} className={ isMenuOpen ? "fixed z-9999 mw-900 w-100  right-0 top-0  min-vh-100 bg-white shadow-5" : "dn" }>
        <div className="pa2  vh-100 overflow-x-hidden overflow-y-auto momentum" onMouseUp={menuFunction} >
          <Contents />
        </div> 
      </div>
      </div>
    </div>
  </>
)
}

export default Nav